body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  overflow: hidden;
  height: 100rem;

}



::-webkit-scrollbar {
  width: 5px;

}

/* Estilize o controle deslizante (thumb) da barra de rolagem */
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

/* Estilize a pista (track) da barra de rolagem */
::-webkit-scrollbar-track {
  background-color: #0000001f;
  border-radius: 5px;
}

.dark-mode ::-webkit-scrollbar-track {
  background-color: #ffffff1f;
  border-radius: 5px;
}

#custom-cursor {
  width: 50px;
  height: 50px;
  border: 1px solid;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  /* Isso permite que o cursor do sistema continue funcionando. */
  transition: transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.titulo {
  opacity: 0.7;
}

.dark-mode {
  background-color: #2c2b2b;
  color: #ffffff;
}

.dark-mode .email {
  border-left: 2px solid white;
}

.dark-mode .experiences:hover {
  background-color: rgba(255, 255, 255, 0.07);
  opacity: 1;
  box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.3);
}

.dark-mode .portfolio:hover {
  background-color: rgba(255, 255, 255, 0.07);
  box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.3);
}

.dark-mode .imgProject {
  box-shadow: 0px 1px 5px 0px #4e4545;
}

* {
  margin: 0px;
  padding: 0px;

}


.containerPrincipal {
  display: flex;
  justify-content: space-around;
  margin-top: 7%;
  height: 100rem;

}

.eu {
  display: flex;
  width: 40%;
  flex-direction: column;
  cursor: default;
  overflow-y: auto;
}

.conteudo {
  width: 50%;
  padding: 20px;
  overflow-y: auto;
  max-height: 700px
}

.desc {
  padding-top: 22%;
  padding-bottom: 13%;
  opacity: 0.3;
  transition: 0.4s;

}

.desc:hover {
  opacity: 1;
  transition: 0.4s;

}

.eu h1 {
  font-size: 45px;
}

.experiences {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
  padding: 30px;
  border-radius: 11px;
  transition: background-color 0.3s, opacity 0.3s;
  cursor: default;
  opacity: 0.3;
}

.experiences:hover {
  background-color: rgb(2 2 2 / 7%);
  opacity: 1;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
}

#readIcon {
  opacity: 0;
  margin-bottom: 25%;
  font-size: large;
}

#linkIcon {
  position: absolute;
  opacity: 0;
}

.timeExperience {
  opacity: 0.7;
  font-weight: bold;
  font-size: small;
}

.infoExperience {
  width: 80%;
}

.infoExperience p {
  margin-top: 2%;
}

.stacks {
  max-width: 100%;
  margin-top: 5%;
  flex-wrap: wrap;
}

.socials {
  display: flex;
  flex-direction: column;

}

.socials a .icons {
  transition: 0.4s;

}

.socials a:hover .icons {
  font-size: 34px;
  transition: 0.4s;
}

.socials a {
  width: fit-content;
  margin-bottom: 2%;

}

.email {
  margin-left: 2%;
  padding: 3% 3% 2% 2%;
  border-left: 2px solid black;
  transition: 1s;
  width: fit-content;
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  align-items: center;
}

.email::selection {
  background-color: #007bff;
  color: #fff;
}

.email:hover {
  padding-left: 4%;
  transition: 1s;

}

.email .copy {
  margin-left: 2%;
  transition: 0.4s;


}


.email:hover .copy {
  color: #3367d1;
  transition: 0.4s;

}

.email-selected {
  background-color: #3367d1;
  color: #fff;
  transition: background-color 0.5s;
}


.viewCv {
  display: inline-flex;
  width: 34%;
}

.viewCv p {
  margin-top: 3%;
}

.viewCv .cvIcon {
  margin-left: 1%;
  margin-top: 3%;
  transition: 0.4s;


}

.viewCv:hover .cvIcon {
  margin-left: 2%;
  transition: 0.4s;
  color: #8ab4f8;


}

.portfolios {
  margin-top: 20%;
}

.portfolios a {
  margin-top: 5%;

}

.portfolio {
  display: flex;
  padding: 30px;
  border-radius: 11px;
  transition: background-color 0.3s, opacity 0.3s;
  cursor: pointer;
}

.portfolio:hover {
  background-color: rgb(2 2 2 / 7%);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);

}

.imgProject {
  width: 25%;
  height: 100%;
  box-shadow: 0px 1px 5px 0px #9a7d7d;

}

.descPortfolio {
  margin-left: 3%;
}

.descPortfolio p {
  margin-top: 2%;
}

.descPortfolio h3 {
  display: flex;
  justify-content: space-between;
}

.portfolio .portfolioIcon {
  transition: 0.4s;

}

.portfolio:hover .portfolioIcon {
  transition: 0.4s;
  color: #8ab4f8;
}


.dizeres {
  opacity: 0.7;
  cursor: default;
  font-size: 14px;
  margin-top: 15%;
  padding: 5%;
}


@media screen and (min-width: 1025px) and (max-width: 1870px) {

  .viewCv {
    width: 50%;
  }
  .eu{
    max-height: 80vh;
    padding-right: 5%;

  }
  .eu h1 {
    font-size: xx-large;
  }

  .eu h3 {
    font-size: medium;
  }

  .eu p {
    font-size: small;
  }

  .desc {
    padding-top: 15%;
    padding-bottom: 14%;
  }

  .conteudo {
    max-height: 70vh;
  }
}

#michael {
  font-weight: bold;
  transition: fontSize 500ms;

}

#michael:hover {
  font-size: large;
  transition: fontSize 500ms;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .experiences {
    opacity: 0.7;

  }

  .myInfos {
    margin-top: 2%;
  }

  .desc {
    opacity: 0.7;
  }

  #custom-cursor {
    display: none;
  }

  .viewCv {
    width: 50%;
  }

  .eu {
    width: 80%;
    max-height: 80vh;
    padding-right: 5%;

  }

  .conteudo {
    width: 85%;
  }

  .socials {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 40%;
    margin-top: 5%;
  }

  .desc {
    display: none;
  }

  #grid {
    display: none;
  }

  #gridPrincipal {
    width: 100%
  }

  .containerPrincipal {
    margin-top: 0;
    width: 100%;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  .eu h1 {
    font-size: xx-large;
  }

  .desc {
    padding-top: 15%;
    padding-bottom: 16%;
  }

  .conteudo {
    max-height: 70vh;
  }
}

@media screen and (max-width: 767px) {
  .experiences {
    background-color: rgb(2 2 2 / 7%);
    opacity: 1;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
  }

  .myInfos {
    margin-top: 2%;
  }

  .dark-mode .experiences {
    background-color: rgba(255, 255, 255, 0.07);
    opacity: 1;
    box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.3);
  }

  .portfolio {
    background-color: rgb(2 2 2 / 7%);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
  }

  .dark-mode .portfolio {
    background-color: rgba(255, 255, 255, 0.07);
    box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.3);
  }

  .viewCv p {
    margin-top: 10%;
  }

  .viewCv .cvIcon {
    margin-top: 11%;
  }

  #stacksId {
    flex-direction: column;
    align-items: baseline;
  }

  .stacks {
    margin-top: 15%;
  }

  #custom-cursor {
    display: none;
  }

  .viewCv {
    width: 50%;
  }

  .eu {
    width: 80%;
  }

  .conteudo {
    width: 85%; 
  }

  .socials {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 5%;
  }

  .desc {
    display: none;
  }

  #grid {
    display: none;
  }

  #gridPrincipal {
    width: 100%
  }

  .containerPrincipal {
    margin-top: 0;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }

  .eu h1 {
    font-size: xx-large;
  }

  .desc {
    padding-top: 15%;
    padding-bottom: 16%;
  }

  .conteudo {
    max-height: 70vh;
  }
}

@media(max-width: 380px) {
  .experiences {
    background-color: rgb(2 2 2 / 7%);
    opacity: 1;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
  }

  .myInfos {
    margin-top: 2%;
  }

  .dark-mode .experiences {
    background-color: rgba(255, 255, 255, 0.07);
    opacity: 1;
    box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.3);
  }

  .portfolio {
    background-color: rgb(2 2 2 / 7%);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
  }

  .dark-mode .portfolio {
    background-color: rgba(255, 255, 255, 0.07);
    box-shadow: 0px 1px 5px 0px rgba(255, 255, 255, 0.3);
  }

  .viewCv p {
    margin-top: 10%;
  }

  .viewCv .cvIcon {
    margin-top: 11%;
  }

  #stacksId {
    flex-direction: column;
    align-items: baseline;
  }

  .stacks {
    margin-top: 15%;
  }

  #custom-cursor {
    display: none;
  }

  .email {
    font-size: small;
  }

  .viewCv {
    width: 63%;
  }

  .cvIcon {
    font-size: medium;
  }

  .eu {
    width: 80%;
  }

  .eu h1 {
    font-size: large;
  }

  .eu h3 {
    font-size: smaller;
  }

  .conteudo {
    width: 85%;
  }

  .conteudo p {
    font-size: small;
  }

  .conteudo h3 {
    font-size: medium;
  }

  .socials {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 5%;
  }

  .desc {
    display: none;
  }

  #grid {
    display: none;
  }

  #gridPrincipal {
    width: 100%
  }

  .containerPrincipal {
    margin-top: 0;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }

  .desc {
    padding-top: 15%;
    padding-bottom: 16%;
  }

  .conteudo {
    max-height: 70vh;
  }
}

@media screen and (orientation: landscape) {
  .eu {
    margin: 0;
  }

  .myInfos {
    margin-top: 2%;
  }

  .experiences {
    margin-top: 2%;
  }

  .socials {
    margin-top: 0;
  }
}